import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import RouteElementWrapper from "./RouteElementWrapper";
import RequireAuthRouteElementWrapper from "./RequireAuthRouteElementWrapper";
import RootLayout from "./RootLayout";
import { Suspense } from "react";
import React from "react";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";

const LoginPage = React.lazy(() => import("pages/user/login"));
const HomePage = React.lazy(() => import("pages/index"));
const Error404Page = React.lazy(() => import("pages/Error404"));
const Error500 = React.lazy(() => import("pages/Error500"));
const DebugPage = React.lazy(() => import("pages/Debug"));
const PlanningPlacementPage = React.lazy(() => import("pages/planning/placement"));
// const PlanningPlacementGanttPage = React.lazy(() => import("pages/planning/placement/gantt"));
const FormListPage = React.lazy(() => import("./FormListPage"));
const FormPage = React.lazy(() => import("./FormPage")); 

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <Suspense fallback={<FieldsetSkeleton />}>
          <RootLayout />
        </Suspense>
      }
      errorElement={
        <Route
          path="500"
          element={
            <RouteElementWrapper>
              <Error500 />
            </RouteElementWrapper>
          }
        />
      }
    >
      <Route
        path="/planning/placement"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <PlanningPlacementPage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      />
      {/* <Route
        path="/planning/placement/gantt"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <PlanningPlacementGanttPage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      /> */}
      <Route
        path="/user/login"
        element={
          <RouteElementWrapper>
            <LoginPage />
          </RouteElementWrapper>
        }
      />
      <Route
        path="/"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <HomePage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      />
      <Route
        path="/:module"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <FormListPage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      />
      <Route
        path="/:module/:moduleFeatureGroup"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <FormListPage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      />
      <Route
        path="/:module/:moduleFeatureGroup/:formId"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <FormPage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      />
      <Route
        path="/:module/:moduleFeatureGroup/:formId/:view"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <FormPage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      />
      <Route
        path="/:module/:moduleFeatureGroup/:formId/:view/:id"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <FormPage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      />
      <Route
        path="/:module/:moduleFeatureGroup/:formId/:view/:parentFormId/:parentId"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <FormPage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      />
      <Route
        path="/:module/:moduleFeatureGroup/:formId/:view/:id/:parentFormId/:parentId"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <FormPage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      />
      <Route
        path="/debug"
        element={
          <RequireAuthRouteElementWrapper>
            <DebugPage />
          </RequireAuthRouteElementWrapper>
        }
      />
      <Route
        path="500"
        element={
          <RouteElementWrapper>
            <Error500 />
          </RouteElementWrapper>
        }
      />
      <Route
        path="*"
        element={
          <RouteElementWrapper>
            <Error404Page />
          </RouteElementWrapper>
        }
      />
    </Route>
  )
);

export default function CustomRoutes() {
  return <RouterProvider router={router} />;
}
